.header {
    padding: 10px 0px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

    .header-row {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .logo {
            img {
                height: 70px;
                width: auto;
            }
        }

        .navbar {
            .main-menu {
                .menu-link {
                    padding: 0px 16px;
                    font-size: 15px;
                    color: #140342;
                    font-weight: 500;
                    transition: color 0.3s ease-in-out;
                }

                .menu-link:hover {
                    color: #7ED644;
                }

                .dropdown {
                    .dropdown-toggle {
                        padding: 0px 16px;
                        font-size: 15px;
                        color: #140342;
                        font-weight: 500;
                    }

                    .dropdown-menu {
                        padding: 15px;
                        border: 1px solid #E5E5E5;
                        box-shadow: 0px 25px 70px 0px #01213A12;
                        margin-top: 10px;

                        .dropdown-item {
                            font-size: 15px;
                            color: #140342;
                            font-weight: 400;
                            padding: 8px 14px;
                            transition: color 0.3s ease-in-out;
                        }

                        .dropdown-item:hover {
                            background-color: transparent;
                            border-radius: 5px;
                            color: #7ED644;
                        }
                    }
                }
                
                .header-phone-link {
                    display: block;
                    font-size: 15px;
                    font-weight: 600;
                    color: #3851A2;
                    margin-bottom: 0px;
                    padding: 0px 16px;
                    text-decoration: none;
                    transition: color 0.3s ease-in-out;
                }

                .header-phone-link:hover {
                    color: #7ED644;
                }
            }
        }
    }
}