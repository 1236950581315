@media (max-width: 575px) {
    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl,
    .container-xxl {
        padding-left: 10px !important;
        padding-right: 10px !important;
    }

    .container-full-width-padding {
        padding: 0px 10px !important;
    }

    .homepage .video-banner video {
        height: 300px;
    }

    .container-full-width {
        padding-left: 10px !important;
        padding-right: 10px !important;
    }

    .homepage .contact-form .text {
        margin-bottom: 20px;
    }

    .homepage .about-college .heading {
        font-size: 30px;
        line-height: 38px;
        margin-top: 15px;
    }

    .homepage .about-college {
        padding: 30px 0px;
    }

    .homepage .why-clement-college {
        padding: 30px 0px;
    }

    .homepage .why-clement-college .heading {
        font-size: 30px;
    }

    .homepage .why-clement-college .text {
        margin-bottom: 15px;
    }

    .homepage .about-boxes {
        padding: 30px 0px;
    }

    .homepage .blogs {
        padding: 30px 0px;
    }

    .homepage .about-boxes .box {
        margin-bottom: 15px;
    }

    .homepage .blogs .heading {
        font-size: 30px;
    }

    .homepage .blogs .blog-list .small-blog {
        margin-bottom: 15px;
    }

    .homepage .blogs .main-blog {
        margin-bottom: 15px;
    }

    .homepage .blogs .blog-list .small-blog .description .heading {
        line-height: 24px;
        font-size: 18px;
    }

    .homepage .blogs .blog-list .small-blog .description .text {
        margin-top: 10px;
    }

    .blog-details {
        .blog-img {
            width: 100%;
            height: auto;
            border-radius: 10px;
            object-fit: cover;
        }
    }

    .footer {
        .top-footer {
            padding: 30px 0px;

            .footer-links {
                margin-top: 20px;
            }

            .quick-connect-card {
                margin-top: 20px;
            }
        }

        .copyright-footer {
            padding: 15px 0px;

            .copyright-row {
                display: block;

                .accreditation {
                    margin-top: 15px;
                    flex-wrap: wrap;
                    justify-content: center;
                    gap: 0px;

                    .accreditation-logo {
                        width: 200px;
                        height: auto;
                    }

                    .accreditation-logo-bvnpt {
                        width: 150px;
                        height: auto;
                    }
                }
            }
        }
    }

    .program .banner .description .heading {
        font-size: 30px;
    }

    .program .banner .description .text {
        font-size: 16px;
    }

    .program .banner .description {
        margin-top: 30px;
    }

    .program .program-details .questions .answer .table {
        width: 100%;
    }

    .program .program-details .sticky-top {
        position: relative;
        margin-top: 20px;
        margin-left: 0px;
    }

    .program .program-details .sticky-top .apply-online-card {
        margin-top: 20px;
    }

    .program .get-in-touch .contact-box-card {
        padding: 30px;
    }

    .program .get-in-touch .contact-box-card .contact-box-cardbody .quick-contacts-card .quick-contacts-cardbody {
        padding: 30px;
    }

    .program .get-in-touch .contact-box-card .contact-box-cardbody .quick-contacts-card {
        margin-left: 0px;
        margin-top: 20px;
        height: auto;
    }

    .other-pages .page-details .sticky-top {
        position: relative;
        margin-top: 20px;
        margin-left: 0px;
    }

    .other-pages .page-details .sticky-top .apply-online-card {
        margin-top: 20px;
    }

    .other-pages .page-details .border {
        padding: 30px;
    }

    .other-pages .get-in-touch .contact-box-card {
        padding: 20px;
    }

    .other-pages .get-in-touch .contact-box-card .contact-box-cardbody .quick-contacts-card .quick-contacts-cardbody {
        padding: 30px;
    }

    .other-pages .get-in-touch .contact-box-card .contact-box-cardbody .quick-contacts-card {
        margin-left: 0px;
        margin-top: 20px;
        height: auto;
    }

    .other-pages .banner-with-image .image img {
        height: 300px;
    }

    .other-pages .banner-with-image .head .heading {
        font-size: 30px;
    }

    .other-pages .page-details {
        padding: 30px 0px;
    }

    .other-pages .page-details .text {
        margin-bottom: 20px;
    }

    .other-pages .page-details .green-box-form {
        padding: 20px;
    }

    .other-pages .page-details .green-box-form .form {
        padding: 20px;
    }

    .other-pages .get-in-touch.with-b {
        padding: 30px 0px;
    }

    .program .banner .image img {
        height: 350px;
    }

    .program .banner .head .heading {
        font-size: 30px;
    }

    .program .program-details {
        padding: 30px 0px;
    }

    .other-pages .page-details .transcript-box-card {
        padding: 10px;
    }

    .other-pages .blogs-list .blog-box {
        height: auto;
        margin-bottom: 15px;
    }

    .other-pages .blogs-list {
        padding: 30px 0px;
    }

    .program .program-details .sideabout-image {
        margin-left: 0px;
        margin-top: 40px;
        margin-bottom: 20px;
    }

    .other-pages .page-details .sideabout-image {
        margin-left: 0px;
        margin-top: 40px;
        margin-bottom: 20px;
    }

    .other-pages .page-details .testimonial-box .desc {
        display: block;
    }

    .other-pages .page-details .testimonial-box .desc .text {
        font-size: 16px;
        margin-top: 20px;
    }

    .other-pages .page-details .library-img {
        width: 100%;
        height: auto;
        border-radius: 0px;
    }

    .other-pages .page-details .sticky-top.bottom-section {
        display: block;
    }
}
