@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

/* LAYOUT STYLES */
@import url("./layout/header.scss");
@import url("./layout/footer.scss");

/* PAGES STYLES */
@import url("./pages/homepage.scss");
@import url("./pages/program.scss");
@import url("./pages/other-pages.scss");

/* RESPONSIVE STYLES */
@import url("./responsive.scss");

body {
  font-family: "IBM Plex Sans Condensed", sans-serif;
  margin: auto;
  overflow: auto;
  overflow-x: hidden;
}

.container,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  max-width: 1650px !important;
  padding: 0px 100px;
}

.container-full-width {
  max-width: 100% !important;
  padding: 0px;
}

.container-full-width-padding {
  max-width: 100% !important;
  padding: 0px 50px;
}

.px-0 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.pl-0 {
  padding-left: 0px !important;
}

.pr-0 {
  padding-right: 0px !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.mt-0 {
  margin-top: 0px !important;
}

.mt-2 {
  margin-top: 20px !important;
}

.mb-2 {
  margin-bottom: 20px !important;
}

.form-group {
  margin-bottom: 15px;

  .form-control {
    border: 1px solid #e5e5e5;
    font-size: 16px;
    font-weight: 500;
    color: #000;
    padding: 8px 16px;
  }

  .form-control::placeholder {
    font-size: 14px;
  }

  .form-control:focus {
    box-shadow: none;
    border: 1px solid #7ed644;
  }

  .phone-control {
    .PhoneInputCountry {
      border-radius: 5px;
      background-color: #fff;
      border: 1px solid #e5e5e5;
      padding: 10px;
    }

    .PhoneInputInput {
      border: 1px solid #e5e5e5;
      font-size: 16px;
      font-weight: 500;
      color: #000;
      padding: 8px 16px;
      border-radius: 5px;
    }

    .PhoneInputInput::placeholder {
      font-size: 14px;
    }
  }

  .react-select {
    .select-control__control {
      height: 42px;
      border: 1px solid #e5e5e5;
      font-size: 16px;
      font-weight: 500;
      color: #000;
      border-radius: 5px;

      .select-control__value-container {
        .select-control__placeholder {
          font-size: 14px;
        }
      }
    }

    .select-control__indicators {
      .select-control__indicator-separator {
        display: none;
      }
    }
  }
}

.logo {
  height: 70px;
  width: auto;
}

body::-webkit-scrollbar {
  display: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  appearance: textfield;
  -moz-appearance: textfield;
}

.css-13cymwt-control {
  border: 2px solid #dcdee2 !important;
  border-radius: 10px !important;
  padding: 5px 10px;
  line-height: 1.5;
  font-size: 14px;
}

.filters-applied {
  display: flex;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 15px;

  p {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 0px;
    margin-right: 10px;
  }

  .applied-filters-list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;

    .applied-filter {
      display: flex;
      align-items: center;
      background-color: rgba($color: #3b4f76, $alpha: 0.2);
      border-radius: 5px;
      padding: 5px 10px;
      margin-right: 5px;

      p {
        font-weight: 400;
        font-size: 12px;
        color: #3b4f76;
      }

      .close-btn {
        min-height: auto;
        padding: 0px;
        background-color: transparent;
        border-color: transparent;
      }
    }
  }
}

.truncate-text {
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Limit to 2 lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: 0px;
  margin-top: 10px;
  font-size: 14px;
  font-weight: 400;
  color: #848e9f;
}

.blogs-pagination {
  margin-top: 20px;
  display: flex;
  align-items: center;
  list-style-type: none;
  justify-content: center;

  .previous,
  .next {
    background-color: #fff;
    border-radius: 50%;
    padding: 4px 10px;
    border: 1px solid #e5e5e5;

    a {
      text-decoration: none;
      color: #323232;
    }
  }

  .blog-pagination-btn {
    height: 35px;
    width: 35px;
    border-radius: 50%;
    background-color: #fff;
    border: 1px solid #e5e5e5;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px;
  }

  li {
    height: 35px;
    width: 35px;
    border-radius: 50%;
    border: 1px solid #e5e5e5;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    cursor: pointer;

    a {
      font-size: 14px;
      font-weight: 600;
    }
  }

  li:hover {
    background-color: #253b4a;

    a {
      color: #fff;
    }
  }
  .active {
    background-color: #253b4a;

    a {
      color: #fff;
    }
  }
}
.description-inner h2 {
  font-size: 22px;
  color: #FFF;
  font-weight: 600;
  margin-bottom: 10px;
}

.description-inner p {
  font-size: 16px;
  font-weight: 400;
  color: #848E9F;
}
.description-inner a {
  text-decoration: none;
}
