.program {
    .banner {
        position: relative;

        .image {
            img {
                width: 100%;
                height: 550px;
                object-fit: cover;
            }
        }

        .head {
            width: 100%;
            position: absolute;
            left: 0;
            bottom: 0;
            background-color: rgba($color: #213360, $alpha: 0.8);
            padding: 20px 0px;

            .heading {
                font-size: 40px;
                font-weight: 700;
                color: #FFF;
                margin-bottom: 0px;
            }
        }
    }

    .program-details {
        padding: 60px 0px;

        .big-text {
            font-size: 20px;
            font-weight: 500;
            margin-bottom: 40px;
        }

        .heading {
            font-size: 24px;
            font-weight: 700;
            color: #7ED644;
            margin-bottom: 0px;
        }

        .text {
            font-size: 15px;
            font-weight: 400;
            color: #848E9F;
            line-height: 25px;
            margin-bottom: 0px;
        }

        .tagline {
            font-size: 18px;
            color: #253B46;
            font-weight: 700;
            margin-bottom: 0px;
        }

        .list {
            margin-top: 10px;

            li {
                font-size: 15px;
                font-weight: 400;
                color: #848E9F;
                line-height: 25px;
                margin-bottom: 0px;
            }
        }

        .table {
            width: 70%;
            border: 1px solid #DCDEE2;
            margin-bottom: 20px;

            thead {
                border-bottom: 1px solid #DCDEE2;

                th:first-child {
                    border-right: 1px solid #DCDEE2;
                }

                th {
                    font-size: 15px;
                    font-weight: 700;
                    color: #848E9F;
                    padding: 5px 15px;
                    text-align: center;
                }
            }

            tbody {
                tr {
                    td {
                        font-size: 15px;
                        font-weight: 400;
                        color: #848E9F;
                        padding: 5px 15px;
                        text-align: center;
                    }

                    td:first-child {
                        border-right: 1px solid #DCDEE2;
                    }
                }
            }
        }

        .table.border {
            width: 100%;

            thead {
                background-color: #DEF8F6;

                a {
                    background-color: transparent;
                }

                th {
                    border-right: 1px solid #DCDEE2;
                }
            }

            tbody {
                tr {
                    td {
                        border-right: 1px solid #DCDEE2;
                    }
                }
            }
        }

        .table:last-child {
            margin-bottom: 0px;
        }

        .questions {
            .accordion-item {
                border-bottom: 2px solid #e7ebef;
                border-radius: 0px;
                border-left: 0px solid transparent;
                border-right: 0px solid transparent;
                background-color: #fff;
                padding: 5px 0px;

                .accordion-header {
                    .accordion-button {
                        font-weight: 800;
                        font-size: 18px;
                        color: #7ED644;
                    }
                
                    .accordion-button::after {
                        background-size: 1rem;
                    }
                
                    .accordion-button:not(.collapsed) {
                        background-color: #FFF;
                        box-shadow: none;
                    }
                
                    .accordion-button.collapsed {
                        box-shadow: none;
                        color: #253B46;
                    }
                }
            }

            .accordion-item:first-child {
                border-top: 2px solid #E7EBEF;
            }

            .answer {
                padding-top: 10px;

                
            }
        }

        .sticky-top {
            position: sticky;
            top: 10px;
            margin-left: 40px;

            .campus-tour-card {
                background-color: #0F203B;

                .campus-tour-cardbody {
                    padding: 40px;

                    .heading {
                        font-size: 22px;
                        color: #FFF;
                        font-weight: 700;
                        margin-bottom: 10px;
                    }

                    .text {
                        font-size: 16px;
                        font-weight: 400;
                        color: #FFF;
                        margin-top: 20px;
                        margin-bottom: 10px;
                    }

                    .action-btn {
                        margin-top: 20px;
                        background-color: #213360;
                        border: 1px solid #213360;
                        padding: 15px 30px;
                        font-size: 15px;
                        color: #FFF;
                        font-weight: 600;
                        transition: background-color 0.3s ease-in-out;
                        display: flex;
                        align-items: center;
                        gap: 10px;

                        .right-arrow {
                            height: 15px;
                            width: 15px;
                            stroke-width: 2px;
                        }
                    }

                    .action-btn:hover {
                        background-color: #7ED644;
                    }
                }
            }

            .campus-tour-card:after {
                content: "";
                position: absolute;
                top: 40px;
                bottom: 40px;
                left: -1px;
                width: 3px;
                background-color: #7ed644;
            }

            .apply-online-card {
                background-color: #F6F6F6;
                border: 0px solid transparent;
                margin-top: 40px;

                .apply-online-cardbody {
                    padding: 40px;

                    .heading {
                        font-size: 22px;
                        color: #253B46;
                        font-weight: 700;
                        margin-bottom: 10px;
                    }

                    .text {
                        font-size: 16px;
                        font-weight: 400;
                        color: #848E9F;
                        margin-top: 20px;
                        margin-bottom: 10px;
                    }

                    .action-btn {
                        margin-top: 20px;
                        background-color: #213360;
                        border: 1px solid #213360;
                        padding: 15px 30px;
                        font-size: 15px;
                        color: #FFF;
                        font-weight: 600;
                        transition: background-color 0.3s ease-in-out;
                        display: flex;
                        align-items: center;
                        gap: 10px;

                        .right-arrow {
                            height: 15px;
                            width: 15px;
                            stroke-width: 2px;
                        }
                    }

                    .action-btn:hover {
                        background-color: #7ED644;
                    }
                }
            }

            .apply-online-card:after {
                content: "";
                position: absolute;
                top: 40px;
                bottom: 40px;
                left: -1px;
                width: 3px;
                background-color: #7ed644;
            }
        }

        .sideabout-image {
            margin-left: 40px;
            margin-bottom: 40px;

            img {
                height: auto;
                border-radius: 10px;
            }
        }
    }

    .get-in-touch {
        padding-bottom: 60px;

        .contact-box-card {
            padding: 60px;
            border-radius: 20px;
            background-color: #FFF;
            box-shadow: 0 5px 83px 0 rgba(9, 29, 62, 0.1);
            border: 0px solid transparent;

            .contact-box-cardbody {
                padding: 0px;

                .heading {
                    font-size: 24px;
                    font-weight: 700;
                    color: #253B46;
                    margin-bottom: 0px;
                }

                .contact-form {
                    margin-top: 30px;

                    .form-group {
                        margin-bottom: 25px;
                    }

                    .form-control {
                        border: 2px solid #DCDEE2;
                        border-radius: 10px;
                        padding: 10px 20px;
                    }

                    .form-control:focus {
                        border: 2px solid #7ED644;
                    }

                    .form-control::placeholder {
                        color: grey;
                        font-weight: 400;
                        font-size: 14px;
                    }

                    .PhoneInputCountry {
                        border-radius: 10px;
                        background-color: #FFF;
                        border: 2px solid #DCDEE2;
                        padding: 10px;
                    }

                    .PhoneInputInput {
                        border: 2px solid #DCDEE2;
                        font-size: 16px;
                        font-weight: 500;
                        color: #000;
                        padding: 10px 20px;
                        border-radius: 10px;
                    }

                    .PhoneInputInput::placeholder {
                        font-size: 14px;
                        font-weight: 400;
                        color: grey;
                    }

                    .select-control__control {
                        height: auto;
                        border: 2px solid #DCDEE2;
                        border-radius: 15px;

                        .select-control__value-container {
                            padding: 8px 20px;

                            .select-control__placeholder {
                                font-size: 14px;
                                font-weight: 400;
                                color: grey;
                            }
                        }
                    }
                    
                    .action-btn {
                        width: 100%;
                        background-color: #213360;
                        border: 1px solid #213360;
                        font-size: 16px;
                        color: #FFF;
                        font-weight: 600;
                        padding: 15px 0px;
                        text-align: center;
                        border-radius: 15px;
                        transition: background-color 0.3s ease-in-out;
                        margin-top: 20px;
                    }

                    .action-btn:hover {
                        background-color: #7ED644;
                        border-color: #7ED644;
                    }

                    .small-text {
                        font-size: 13px;
                        font-weight: 400;
                        color: #848E9F;
                        margin-top: 15px;
                        margin-bottom: 0px;
                        line-height: 24px;
                    }
                }

                .quick-contacts-card {
                    background-color: #7ED644;
                    border: 0px solid transparent;
                    border-radius: 20px;
                    margin-left: 50px;
                    height: 100%;

                    .quick-contacts-cardbody {
                        padding: 55px 60px;

                        .heading {
                            font-size: 30px;
                            color: #FFF;
                            margin-bottom: 0px;
                        }

                        .text {
                            font-size: 16px;
                            font-weight: 600;
                            color: #FFF;
                            margin-top: 20px;
                        }

                        .contact-details {
                            margin-top: 40px;

                            .cta {
                                display: flex;
                                align-items: center;
                                text-decoration: none;
                                gap: 10px;
                                margin-bottom: 10px;
                                color: #FFF;

                                svg {
                                    height: 20px;
                                    width: 20px;
                                    flex-shrink: 0;
                                }

                                p {
                                    font-size: 16px;
                                    font-weight: 600;
                                    color: #FFF;
                                    margin-bottom: 0px;
                                }
                            }

                            .contact-btn {
                                margin-top: 20px;
                                background-color: transparent;
                                border: 2px solid #FFF;
                                border-radius: 15px;
                                padding: 10px 20px;
                                font-weight: 600;
                                font-size: 16px;
                                transition: background-color 0.3s ease-in-out;
                            }

                            .contact-btn:hover {
                                background-color: rgba($color: #FFF, $alpha: 0.2);
                            }
                        }
                    }
                }
            }
        }
    }
}