.other-pages {
    .banner-with-image {
        position: relative;

        .image {
            img {
                width: 100%;
                height: 450px;
                object-fit: cover;
            }
        }

        .head {
            width: 100%;
            position: absolute;
            left: 0;
            bottom: 0;
            background-color: rgba($color: #213360, $alpha: 0.8);
            padding: 20px 0px;

            .heading {
                font-size: 40px;
                font-weight: 700;
                color: #fff;
                margin-bottom: 0px;
            }
        }
    }

    .page-details {
        padding: 60px 0px;

        .border {
            border: 2px solid #e6e8eb;
            border-radius: 15px;
            padding: 40px;
        }

        .heading {
            font-size: 25px;
            font-weight: 700;
            color: #253b46;
            margin-top: 30px;
        }

        .library-img {
            margin: auto;
            margin-bottom: 20px;
            border-radius: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .bottom-border {
            margin-top: 20px;
            border-top: 1px solid #dcdee2;
            padding-top: 15px;

            .heading {
                font-size: 18px;
                color: #848e9f;
                margin-top: 0px;

                a {
                    text-decoration: none;
                    color: #7ed644;
                }
            }
        }

        .text {
            font-size: 16px;
            font-weight: 400;
            color: #848e9f;
            margin-bottom: 10px;
        }

        .new-para {
            margin-top: 20px;

            .heading {
                font-size: 25px;
                font-weight: 700;
                color: #253b46;
            }

            .point {
                display: flex;
                align-items: flex-start;
                gap: 10px;
                margin-bottom: 20px;

                .box {
                    height: 25px;
                    width: 25px;
                    background-color: #7ed644;
                    border-radius: 50%;
                    flex-shrink: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    svg {
                        height: 14px;
                        width: 14px;
                        color: #fff;
                        stroke: #fff;
                        stroke-width: 2px;
                    }
                }

                .text {
                    margin-bottom: 0px;
                }
            }
        }

        .list {
            li {
                font-size: 16px;
                font-weight: 400;
                color: #848e9f;
                margin-bottom: 10px;
            }

            li:last-child {
                margin-bottom: 0px;
            }
        }

        .approval-box {
            .accred-logo {
                height: 140px;
                width: 100%;
                display: flex;
                align-items: flex-end;

                img {
                    width: 90%;
                    height: auto;
                }
            }

            .heading {
                font-size: 16px;
                font-weight: 700;
                color: #848e9f;
                margin-top: 20px;
            }

            .text {
                font-size: 14px;
                font-weight: 400;
                color: #848e9f;
                margin-bottom: 5px;

                a {
                    color: #7ed644;
                    font-weight: 500;
                    text-decoration: none;
                }
            }
        }

        .sticky-top {
            position: sticky;
            top: 10px;
            margin-left: 40px;

            .campus-tour-card {
                background-color: #0f203b;

                .campus-tour-cardbody {
                    padding: 40px;

                    .heading {
                        font-size: 22px;
                        color: #fff;
                        font-weight: 700;
                        margin-bottom: 10px;
                        margin-top: 0px;
                    }

                    .text {
                        font-size: 16px;
                        font-weight: 400;
                        color: #fff;
                        margin-top: 20px;
                        margin-bottom: 10px;
                    }

                    .action-btn {
                        margin-top: 20px;
                        background-color: #213360;
                        border: 1px solid #213360;
                        padding: 15px 30px;
                        font-size: 15px;
                        color: #fff;
                        font-weight: 600;
                        transition: background-color 0.3s ease-in-out;
                        display: flex;
                        align-items: center;
                        gap: 10px;

                        .right-arrow {
                            height: 15px;
                            width: 15px;
                            stroke-width: 2px;
                        }
                    }

                    .action-btn:hover {
                        background-color: #7ed644;
                    }
                }
            }

            .campus-tour-card:after {
                content: "";
                position: absolute;
                top: 40px;
                bottom: 40px;
                left: -1px;
                width: 3px;
                background-color: #7ed644;
            }

            .apply-online-card {
                background-color: #f6f6f6;
                border: 0px solid transparent;
                margin-top: 40px;

                .apply-online-cardbody {
                    padding: 40px;

                    .heading {
                        font-size: 22px;
                        color: #253b46;
                        font-weight: 700;
                        margin-bottom: 10px;
                        margin-top: 0px;
                    }

                    .text {
                        font-size: 16px;
                        font-weight: 400;
                        color: #848e9f;
                        margin-top: 20px;
                        margin-bottom: 10px;
                    }

                    .action-btn {
                        margin-top: 20px;
                        background-color: #213360;
                        border: 1px solid #213360;
                        padding: 15px 30px;
                        font-size: 15px;
                        color: #fff;
                        font-weight: 600;
                        transition: background-color 0.3s ease-in-out;
                        display: flex;
                        align-items: center;
                        gap: 10px;

                        .right-arrow {
                            height: 15px;
                            width: 15px;
                            stroke-width: 2px;
                        }
                    }

                    .action-btn:hover {
                        background-color: #7ed644;
                    }
                }
            }

            .apply-online-card:after {
                content: "";
                position: absolute;
                top: 40px;
                bottom: 40px;
                left: -1px;
                width: 3px;
                background-color: #7ed644;
            }
        }

        .sticky-top.bottom-section {
            margin-left: 0px;
            margin-top: 40px;
            display: flex;
            align-items: center;
            width: 100%;
            gap: 40px;

            .campus-tour-card {
                width: 100%;
            }

            .apply-online-card {
                width: 100%;
                margin-top: 0px;
            }
        }

        .name {
            font-size: 18px;
            font-weight: 700;
            color: #213360;
            margin-bottom: 0px;
        }

        .designation {
            font-size: 14px;
            font-weight: 400;
            color: #848e9f;
        }

        .green-box-form {
            margin-top: 20px;
            border-radius: 15px;
            background-color: #7ed644;
            padding: 50px;

            .form {
                background-color: #fff;
                box-shadow: 0 5px 83px 0 rgba(9, 29, 62, 0.1);
                padding: 50px;
                border-radius: 15px;

                .heading {
                    margin-top: 0px;
                }

                .form-group {
                    margin-bottom: 25px;

                    .form-label {
                        font-weight: 600;
                    }

                    .checkbox-list {
                        display: flex;
                        align-items: center;
                        gap: 15px;
                    }
                }

                .form-control {
                    border: 2px solid #dcdee2;
                    border-radius: 10px;
                    padding: 10px 20px;
                }

                .form-control:focus {
                    border: 2px solid #7ed644;
                }

                .form-control::placeholder {
                    color: grey;
                    font-weight: 400;
                    font-size: 14px;
                }

                .PhoneInputCountry {
                    border-radius: 10px;
                    background-color: #fff;
                    border: 2px solid #dcdee2;
                    padding: 10px;
                }

                .PhoneInputInput {
                    border: 2px solid #dcdee2;
                    font-size: 16px;
                    font-weight: 500;
                    color: #000;
                    padding: 10px 20px;
                    border-radius: 10px;
                }

                .PhoneInputInput::placeholder {
                    font-size: 14px;
                    font-weight: 400;
                    color: grey;
                }

                .select-control__control {
                    height: auto;
                    border: 2px solid #dcdee2;
                    border-radius: 15px;

                    .select-control__value-container {
                        padding: 8px 20px;

                        .select-control__placeholder {
                            font-size: 14px;
                            font-weight: 400;
                            color: grey;
                        }
                    }
                }

                .action-btn {
                    width: 100%;
                    background-color: #213360;
                    border: 1px solid #213360;
                    font-size: 16px;
                    color: #fff;
                    font-weight: 600;
                    padding: 15px 0px;
                    text-align: center;
                    border-radius: 15px;
                    transition: background-color 0.3s ease-in-out;
                    margin-top: 20px;
                }

                .action-btn:hover {
                    background-color: #7ed644;
                    border-color: #7ed644;
                }

                .small-text {
                    font-size: 13px;
                    font-weight: 400;
                    color: #848e9f;
                    margin-top: 15px;
                    margin-bottom: 0px;
                    line-height: 24px;
                }

                .req-info {
                    width: 100%;
                    background-color: #7ed644;
                    color: #fff;
                    font-size: 16px;
                    font-weight: 600;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: 1px solid #7ed644;
                    padding: 15px 0px;
                    text-align: center;
                    gap: 10px;
                    transition: background-color 0.3s ease-in-out;

                    svg {
                        height: 15px;
                        width: 15px;
                        stroke: #fff;
                        stroke-width: 2px;
                    }
                }

                .req-info:hover {
                    background-color: #213360;
                    border-color: #213360;
                }
            }
        }

        .transcript-box-card {
            border: 2px solid #e6e8eb;
            border-radius: 10px;
            margin-top: 30px;
            padding: 20px;

            .heading {
                font-size: 24px;
                font-weight: 700;
                color: #253b46;
                margin-bottom: 0px;
            }

            .contact-form {
                .form-group {
                    margin-bottom: 25px;
                }

                .form-control {
                    border: 2px solid #dcdee2;
                    border-radius: 10px;
                    padding: 10px 20px;
                }

                .form-control:focus {
                    border: 2px solid #7ed644;
                }

                .form-control::placeholder {
                    color: grey;
                    font-weight: 400;
                    font-size: 14px;
                }

                .PhoneInputCountry {
                    border-radius: 10px;
                    background-color: #fff;
                    border: 2px solid #dcdee2;
                    padding: 10px;
                }

                .PhoneInputInput {
                    border: 2px solid #dcdee2;
                    font-size: 16px;
                    font-weight: 500;
                    color: #000;
                    padding: 10px 20px;
                    border-radius: 10px;
                }

                .PhoneInputInput::placeholder {
                    font-size: 14px;
                    font-weight: 400;
                    color: grey;
                }

                .select-control__control {
                    height: auto;
                    border: 2px solid #dcdee2;
                    border-radius: 15px;

                    .select-control__value-container {
                        padding: 8px 20px;

                        .select-control__placeholder {
                            font-size: 14px;
                            font-weight: 400;
                            color: grey;
                        }
                    }
                }

                .action-btn {
                    width: 100%;
                    background-color: #213360;
                    border: 1px solid #213360;
                    font-size: 16px;
                    color: #fff;
                    font-weight: 600;
                    padding: 15px 0px;
                    text-align: center;
                    border-radius: 15px;
                    transition: background-color 0.3s ease-in-out;
                    margin-top: 20px;
                }

                .action-btn:hover {
                    background-color: #7ed644;
                    border-color: #7ed644;
                }

                .small-text {
                    font-size: 13px;
                    font-weight: 400;
                    color: #848e9f;
                    margin-top: 15px;
                    margin-bottom: 0px;
                    line-height: 24px;
                }
            }
        }

        .sideabout-image {
            margin-left: 40px;
            margin-bottom: 40px;

            img {
                height: auto;
                border-radius: 10px;
            }
        }

        .admin {
            width: 100%;
            height: auto;
            border-radius: 10px;
            object-fit: cover;
            margin-bottom: 20px;
        }

        .testimonial-box {
            width: 100%;
            border-radius: 5px;
            padding: 30px;
            background-color: rgba(126, 214, 68, 0.05);
            position: relative;
            margin-bottom: 20px;

            .desc {
                display: flex;
                align-items: center;
                gap: 30px;

                img {
                    width: 140px;
                    height: auto;
                    object-fit: cover;
                    border-radius: 5px;
                }

                .text {
                    font-size: 18px;
                    color: #0f203b;
                    font-weight: 400;
                }
            }

            .quote-icon {
                position: absolute;
                bottom: 5px;
                right: 5px;
                width: 50px;
                height: auto;
                opacity: 0.2;
            }
        }
    }

    .get-in-touch {
        padding-bottom: 60px;

        .contact-box-card {
            padding: 60px;
            border-radius: 20px;
            background-color: #fff;
            box-shadow: 0 5px 83px 0 rgba(9, 29, 62, 0.1);
            border: 0px solid transparent;

            .contact-box-cardbody {
                padding: 0px;

                .heading {
                    font-size: 24px;
                    font-weight: 700;
                    color: #253b46;
                    margin-bottom: 0px;
                }

                .contact-form {
                    margin-top: 30px;

                    .form-group {
                        margin-bottom: 25px;
                    }

                    .form-control {
                        border: 2px solid #dcdee2;
                        border-radius: 10px;
                        padding: 10px 20px;
                    }

                    .form-control:focus {
                        border: 2px solid #7ed644;
                    }

                    .form-control::placeholder {
                        color: grey;
                        font-weight: 400;
                        font-size: 14px;
                    }

                    .PhoneInputCountry {
                        border-radius: 10px;
                        background-color: #fff;
                        border: 2px solid #dcdee2;
                        padding: 10px;
                    }

                    .PhoneInputInput {
                        border: 2px solid #dcdee2;
                        font-size: 16px;
                        font-weight: 500;
                        color: #000;
                        padding: 10px 20px;
                        border-radius: 10px;
                    }

                    .PhoneInputInput::placeholder {
                        font-size: 14px;
                        font-weight: 400;
                        color: grey;
                    }

                    .select-control__control {
                        height: auto;
                        border: 2px solid #dcdee2;
                        border-radius: 15px;

                        .select-control__value-container {
                            padding: 8px 20px;

                            .select-control__placeholder {
                                font-size: 14px;
                                font-weight: 400;
                                color: grey;
                            }
                        }
                    }

                    .action-btn {
                        width: 100%;
                        background-color: #213360;
                        border: 1px solid #213360;
                        font-size: 16px;
                        color: #fff;
                        font-weight: 600;
                        padding: 15px 0px;
                        text-align: center;
                        border-radius: 15px;
                        transition: background-color 0.3s ease-in-out;
                        margin-top: 20px;
                    }

                    .action-btn:hover {
                        background-color: #7ed644;
                        border-color: #7ed644;
                    }

                    .small-text {
                        font-size: 13px;
                        font-weight: 400;
                        color: #848e9f;
                        margin-top: 15px;
                        margin-bottom: 0px;
                        line-height: 24px;
                    }
                }

                .quick-contacts-card {
                    background-color: #7ed644;
                    border: 0px solid transparent;
                    border-radius: 20px;
                    margin-left: 50px;
                    height: 100%;

                    .quick-contacts-cardbody {
                        padding: 55px 60px;

                        .heading {
                            font-size: 30px;
                            color: #fff;
                            margin-bottom: 0px;
                        }

                        .text {
                            font-size: 16px;
                            font-weight: 600;
                            color: #fff;
                            margin-top: 20px;
                        }

                        .contact-details {
                            margin-top: 40px;

                            .cta {
                                display: flex;
                                align-items: center;
                                text-decoration: none;
                                gap: 10px;
                                margin-bottom: 10px;
                                color: #fff;

                                svg {
                                    height: 20px;
                                    width: 20px;
                                    flex-shrink: 0;
                                }

                                p {
                                    font-size: 16px;
                                    font-weight: 600;
                                    color: #fff;
                                    margin-bottom: 0px;
                                }
                            }

                            .contact-btn {
                                margin-top: 20px;
                                background-color: transparent;
                                border: 2px solid #fff;
                                border-radius: 15px;
                                padding: 10px 20px;
                                font-weight: 600;
                                font-size: 16px;
                                transition: background-color 0.3s ease-in-out;
                            }

                            .contact-btn:hover {
                                background-color: rgba($color: #fff, $alpha: 0.2);
                            }
                        }
                    }
                }
            }
        }
    }

    .get-in-touch.with-b {
        padding: 60px 0px;

        .contact-box-card {
            box-shadow: none;
            border: 2px solid #e6e8eb;

            .heading {
                margin-bottom: 10px;
            }

            .text {
                font-size: 16px;
                font-weight: 400;
                color: #848e9f;
                margin-bottom: 10px;
            }

            .contact-form .action-btn {
                margin-top: 0px !important;
            }

            .quick-contacts-cardbody {
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex-direction: column;
            }
        }
    }

    .instructions {
        padding-bottom: 60px;

        .side-boxes {
            display: flex;
            align-items: center;

            .box.left {
                width: 100%;
                background-color: #7ed644;
                border-top-left-radius: 15px;
                border-bottom-left-radius: 15px;
                padding: 40px 50px;

                .heading {
                    font-size: 20px;
                    color: #fff;
                    margin-bottom: 0px;
                    font-weight: 700;
                }

                .directions {
                    margin-top: 20px;

                    .text {
                        font-size: 16px;
                        font-weight: 400;
                        color: #fff;
                        margin-bottom: 5px;
                    }
                }
            }

            .box.right {
                width: 100%;
                background-color: #3851a2;
                border-top-right-radius: 15px;
                border-bottom-right-radius: 15px;
                padding: 40px 50px;

                .heading {
                    font-size: 20px;
                    color: #fff;
                    margin-bottom: 0px;
                    font-weight: 700;
                }

                .directions {
                    margin-top: 20px;

                    .text {
                        font-size: 16px;
                        font-weight: 400;
                        color: #fff;
                        margin-bottom: 5px;
                    }
                }
            }
        }
    }

    .blogs-list {
        padding: 60px 0px;
        position: relative;

        .blog-box {
            border: 1px solid #e5e5e5;
            border-radius: 5px;
            box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
            height: 100%;
            position: relative;

            img {
                width: 100%;
                height: 300px;
                object-fit: cover;
                border-radius: 5px;
                transition: all 0.5s ease-in-out;
            }

            .details {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 95%;
                padding: 15px;
                background-color: #fff;
                border-top-right-radius: 10px;
                border-bottom-left-radius: 5px;

                .heading {
                    font-size: 17px;
                    font-weight: 700;
                    color: #000;
                    margin-bottom: 0px;
                }

                .text {
                    margin-bottom: 0px;
                    margin-top: 10px;
                    font-size: 14px;
                    font-weight: 400;
                    color: #848e9f;
                }
            }
        }
    }

    .blogs-list:hover {
        .blog-box img {
            -webkit-transform: rotate(1deg);
            transform: rotate(1deg);
        }
    }
}

.blog-details {
    padding: 60px 0px;

    .sticky-top {
        position: sticky;
        top: 10px;
        margin-left: 40px;

        .campus-tour-card {
            background-color: #0f203b;

            .campus-tour-cardbody {
                padding: 40px;

                .heading {
                    font-size: 22px;
                    color: #fff;
                    font-weight: 700;
                    margin-bottom: 10px;
                }

                .text {
                    font-size: 16px;
                    font-weight: 400;
                    color: #fff;
                    margin-top: 20px;
                    margin-bottom: 10px;
                }

                .action-btn {
                    margin-top: 20px;
                    background-color: #213360;
                    border: 1px solid #213360;
                    padding: 15px 30px;
                    font-size: 15px;
                    color: #fff;
                    font-weight: 600;
                    transition: background-color 0.3s ease-in-out;
                    display: flex;
                    align-items: center;
                    gap: 10px;

                    .right-arrow {
                        height: 15px;
                        width: 15px;
                        stroke-width: 2px;
                    }
                }

                .action-btn:hover {
                    background-color: #7ed644;
                }
            }
        }

        .campus-tour-card:after {
            content: "";
            position: absolute;
            top: 40px;
            bottom: 40px;
            left: -1px;
            width: 3px;
            background-color: #7ed644;
        }

        .apply-online-card {
            background-color: #f6f6f6;
            border: 0px solid transparent;
            margin-top: 40px;

            .apply-online-cardbody {
                padding: 40px;

                .heading {
                    font-size: 22px;
                    color: #253b46;
                    font-weight: 700;
                    margin-bottom: 10px;
                }

                .text {
                    font-size: 16px;
                    font-weight: 400;
                    color: #848e9f;
                    margin-top: 20px;
                    margin-bottom: 10px;
                }

                .action-btn {
                    margin-top: 20px;
                    background-color: #213360;
                    border: 1px solid #213360;
                    padding: 15px 30px;
                    font-size: 15px;
                    color: #fff;
                    font-weight: 600;
                    transition: background-color 0.3s ease-in-out;
                    display: flex;
                    align-items: center;
                    gap: 10px;

                    .right-arrow {
                        height: 15px;
                        width: 15px;
                        stroke-width: 2px;
                    }
                }

                .action-btn:hover {
                    background-color: #7ed644;
                }
            }
        }

        .apply-online-card:after {
            content: "";
            position: absolute;
            top: 40px;
            bottom: 40px;
            left: -1px;
            width: 3px;
            background-color: #7ed644;
        }
    }

    .text {
        font-size: 16px;
        font-weight: 400;
        color: #848e9f;
        line-height: 26px;
    }

    .heading {
        font-size: 22px;
        font-weight: 700;
        color: #000;
    }

    .blog-img {
        width: 50%;
        height: auto;
        border-radius: 10px;
        object-fit: cover;
    }

    .point {
        display: flex;
        align-items: flex-start;
        gap: 10px;
        margin-bottom: 20px;
        margin-top: 20px;

        .text {
            font-size: 16px;
            font-weight: 400;
            color: #848e9f;
            line-height: 26px;
        }

        .box {
            height: 25px;
            width: 25px;
            background-color: #7ed644;
            border-radius: 50%;
            flex-shrink: 0;
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
                height: 14px;
                width: 14px;
                color: #fff;
                stroke: #fff;
                stroke-width: 2px;
            }
        }

        .point-desc {
            .heading {
                font-size: 18px;
                font-weight: 700;
                color: #848e9f;
                margin-bottom: 0px;
            }

            .text {
                font-size: 16px;
                font-weight: 400;
                color: #848e9f;
                line-height: 26px;
                margin-top: 5px;
                margin-bottom: 0px;
            }
        }
    }
}
