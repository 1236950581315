.footer {
    .top-footer {
        background-color: #253B4A;
        padding: 50px 0px;

        .logo {
            width: 130px;
            height: auto;
        }

        .footer-links {
            h5 {
                font-size: 20px;
                color: #FFF;
                margin-bottom: 15px;
                font-weight: 700;
            }

            .nav-link {
                font-size: 16px;
                font-weight: 500;
                color: #848E9F;
                margin-bottom: 10px;
                transition: color 0.3s ease-in-out;
            }

            .nav-link:hover {
                color: #7ED644;
            }
        }

        .quick-connect-card {
            background-color: #FFF;
            border-radius: 15px;
            padding: 20px;

            .heading-text {
                font-size: 20px;
                font-weight: 600;
                color: #253B46;
                margin-bottom: 0px;
            }

            .phone {
                display: flex;
                align-items: center;
                text-decoration: none;
                margin-top: 30px;
                gap: 8px;

                .icon {
                    height: 22px;
                    width: 22px;
                    color: #3851A2;
                }

                .link {
                    font-size: 22px;
                    font-weight: 700;
                    color: #3851A2;
                    margin-bottom: 0px;
                }
            }

            .phone:hover {
                .icon {
                    color: #7ED644;
                }

                .link {
                    color: #7ED644;
                }
            }

            .address {
                font-size: 14px;
                color: #848E9F;
                font-weight: 400;
                margin-bottom: 0px;
                margin-top: 20px;
            }

            .social-links {
                margin-top: 20px;
                display: flex;
                align-items: center;
                gap: 10px;

                .social-media-btn {
                    width: 35px;
                    height: 35px;
                    background-color: #213360;
                    border: 0px solid transparent;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 0px;
                    transition: background-color 0.3s ease-in-out;
                }

                .social-media-btn:hover {
                    background-color: #7ED644;
                }
            }
        }

        .quick-connect-card::after {
            content: "";
            position: absolute;
            top: 40px;
            bottom: 40px;
            left: 0;
            width: 3px;
            background-color: #7ed644;
        }
    }

    .copyright-footer {
        padding: 25px 0px;
        background-color: #FFF;

        .copyright-row {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .text {
                font-size: 14px;
                color: #848E9F;
                font-weight: 400;
                margin-bottom: 0px;

                a {
                    color: #7ED644;
                    text-decoration: none;
                    font-weight: 600;
                }
            }

            .accreditation {
                display: flex;
                align-items: center;
                gap: 30px;

                .accreditation-logo {
                    width: 230px;
                    height: auto;
                }

                .accreditation-logo-bvnpt {
                    width: 150px;
                    height: auto;
                }

                .accreditation-logo-cdp {
                    width: 120px;
                    height: auto;
                }
            }
        }
    }
}