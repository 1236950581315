.homepage {
    .video-banner {
        margin-bottom: -6px;

        video {
            width: 100%;
            height: 600px;
            object-fit: cover;
        }
    }

    .programs-banner {
        .program-box {
            height: 100%;
            position: relative;

            .banner {
                width: 100%;
                height: 100%;
                object-fit: cover;
                position: relative;
            }

            .overlay {
                position: absolute;
                top: 0;
                height: 100%;
                width: 100%;
                background-color: rgba($color: #000000, $alpha: 0.3);
                padding: 15px;
                display: flex;
                align-items: flex-end;

                .learn-btn {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;
                    background-color: #fff;
                    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
                    border-color: transparent;
                    padding: 10px 20px;

                    svg {
                        transition: fill 0.3s ease-in-out;
                    }

                    p {
                        font-size: 15px;
                        margin-bottom: 0px;
                        font-weight: 600;
                        color: #323232;
                        transition: color 0.3s ease-in-out;
                        text-align: left;
                    }
                }

                .learn-btn:hover {
                    p {
                        color: #7ed644;
                    }

                    svg {
                        fill: #7ed644;
                    }
                }
            }
        }
    }

    .contact-form {
        background-color: #4d758e;
        padding: 50px 0px;

        .heading {
            font-size: 35px;
            color: #fff;
            font-weight: 700;
            margin-bottom: 15px;
        }

        .text {
            font-size: 15px;
            font-weight: 400;
            color: rgba($color: #fff, $alpha: 0.7);
            margin-bottom: 0px;
        }

        .submit-row {
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;

            .submit-btn {
                background-color: #c10001;
                border-radius: 5px;
                border: 1px solid #c10001;
                padding: 8px 30px;
                color: #fff;
                font-size: 16px;
                transition: background-color 0.3s ease-in-out;
            }

            .submit-btn:hover {
                background-color: rgba($color: #c10001, $alpha: 0.8);
            }
        }
    }

    .about-college {
        padding: 50px 0px;

        .banner {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 10px;
        }

        .heading {
            font-size: 35px;
            font-weight: 700;
            color: #000000;
            line-height: 40px;
            margin-bottom: 20px;
        }

        .text {
            font-size: 18px;
            font-weight: 400;
            color: #000000;
            margin-top: 15px;
            margin-bottom: 0px;
        }

        .social-media {
            margin-top: 50px;
            padding: 15px 0px;
            border-top: 1px solid #e5e5e5;
            border-bottom: 1px solid #e5e5e5;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .social-links {
                display: flex;
                align-items: center;
                gap: 10px;

                .social-media-btn {
                    width: 35px;
                    height: 35px;
                    background-color: #fff;
                    border: 1px solid #213360;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 0px;
                    transition: background-color 0.3s ease-in-out;
                }

                .social-media-btn:hover {
                    background-color: #213360;

                    svg {
                        fill: #fff;
                    }
                }
            }

            .learn-more-link {
                font-size: 16px;
                font-weight: 600;
                color: #000000;
                text-decoration: none;
                transition: color 0.3s ease-in-out;
            }

            .learn-more-link:hover {
                color: #7ed644;
            }
        }
    }

    .why-clement-college {
        padding: 50px 0px;

        .heading {
            font-size: 35px;
            font-weight: 700;
            color: #000000;
            line-height: 40px;
            margin-bottom: 20px;
            text-align: center;
        }

        .text {
            font-size: 16px;
            font-weight: 400;
            color: #213360;
            margin-bottom: 0px;
            text-align: center;
        }

        .benefits {
            margin-top: 30px;

            .icon {
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 100%;
                width: 120px;
                height: 120px;
                margin: 0 auto;
                font-size: 50px;
                transition: 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
                box-shadow: 0px 25px 70px 0px #01213a12;

                img {
                    height: 80px;
                    width: 80px;
                }
            }

            .description {
                margin-top: 10px;

                .heading {
                    font-size: 20px;
                    margin-bottom: 0px;
                    color: #213360;
                }
            }
        }
    }

    .about-boxes {
        padding: 50px 0px;

        .box {
            width: 100%;
            background-color: #f5f5f5;
            border-radius: 10px;

            .banner {
                width: 100%;
                height: 350px;
                object-fit: cover;
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
            }

            .description {
                padding: 20px 30px;

                .heading {
                    font-size: 22px;
                    font-weight: 700;
                    color: #213360;
                    margin-bottom: 0px;
                }

                .text {
                    margin-bottom: 0px;
                    margin-top: 10px;
                }
            }
        }
    }

    .tagline {
        padding: 20px 0px;
        background-color: #7ed644;

        .heading {
            font-size: 26px;
            color: #fff;
            font-weight: 700;
            text-align: center;
            margin-bottom: 0px;
        }
    }

    .blogs {
        padding: 50px 0px;

        .heading {
            font-size: 35px;
            font-weight: 700;
            color: #000000;
            line-height: 40px;
            margin-bottom: 20px;
        }

        .main-blog {
            border: 1px solid #e5e5e5;
            border-radius: 10px;
            padding: 15px;
            transition: border 0.3s ease-in-out;

            .blog-img {
                width: 100%;
                height: auto;
                border-radius: 10px;
                object-fit: cover;
            }

            .description {
                margin-top: 15px;

                .heading {
                    font-size: 22px;
                    font-weight: 700;
                    color: #213360;
                    margin-bottom: 0px;
                }

                .text {
                    margin-bottom: 0px;
                }
            }
        }

        .main-blog:hover {
            border: 1px solid #7ed644;
            box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
        }

        .blog-list {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;

            .small-blog {
                border: 1px solid #e5e5e5;
                border-radius: 10px;
                padding: 15px;
                transition: border 0.3s ease-in-out;
                display: flex;
                align-items: center;

                .blog-img {
                    width: 100px;
                    height: 100px;
                    object-fit: cover;
                    border-radius: 10px;
                }

                .description {
                    margin-left: 15px;

                    .heading {
                        font-size: 20px;
                        font-weight: 700;
                        color: #213360;
                        margin-bottom: 0px;
                    }

                    .text {
                        font-size: 15px;
                        margin-bottom: 0px;
                    }
                }
            }

            .small-blog:hover {
                border: 1px solid #7ed644;
                box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
            }
        }
    }

    .primary-btn {
        background-color: #213360;
        margin-top: 15px;
        border: 1px solid #213360;
        font-weight: 600;
        font-size: 16px;
        padding: 8px 20px;
        display: flex;
        align-items: center;
        gap: 10px;

        svg {
            height: 15px;
            width: 15px;
            color: #fff;
            stroke-width: 3px;
        }
    }

    .instagram-wall {
        padding: 60px 0px;

        .instagram-heading-box {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 20px;

            h3 {
                font-size: 35px;
                font-weight: 800;
                text-align: left;
            }

            a {
                text-decoration: none;
                color: #7ed644;
                font-size: 18px;
                font-weight: 600;
            }
        }

        .instagram-link {
            display: block;
            width: 100%;
            height: 400px;
            position: relative;

            .bg-overlay {
                position: absolute;
                opacity: 0;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                background-color: rgba($color: #000000, $alpha: 0.5);
                border-radius: 5px;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: opacity 0.3s ease-in-out;
            }

            .instagram-post {
                width: 100%;
                height: 100%;
            }

            .instagram-post video {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 5px;
            }

            .instagram-post img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 5px;
            }
        }

        .instagram-link:hover {
            .bg-overlay {
                opacity: 1;
            }
        }
    }
}
